import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Department from "./pages/Department";
import Home from "./pages/Home";
import departments from "../contentSite/pagesData";
import Sponsors from "./pages/Sponsors";
import Event from "./pages/Event";
import Events from "./pages/Events";
import wip from "../img/wip.png";
import ScrollToTop from "./ScrollToTop";

const WIP = () => <div className="wip-container">
    <img className="wip" src={wip} alt="wip" />
    <h1 className="wip-text">Costi munceşte aici</h1>
    {setTimeout(() => window.location.replace("/"), 1500)}
</div>

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/bal2022" element={<Event />} />
                <Route path="/wip" element={<WIP />} />
                {departments.map((item, index) =>
                    <Route
                        key={index}
                        path={`/${item.name}`}
                        element={<Department details={item} />}
                    />
                )}
                <Route path="/sponsors" element={<Sponsors />} />
                <Route path="/events" element={<Events />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;