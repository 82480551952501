import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { useState } from "react";

function Gallery(props) {
    const [thumbnails, setThumbnails] = useState(true);
    const [fullscreen, setFullscreen] = useState(true);


    setTimeout(() => setThumbnails(props.thumbnails) ,100);
    setTimeout(() => setFullscreen(props.fullscreen) ,100);


    return <div className="gallery">
        <ImageGallery items={props.gallery} showThumbnails={thumbnails} showFullscreenButton={fullscreen}  />
    </div>
}

export default Gallery;