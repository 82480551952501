import genoveva from "../img/bord/genoveva.jpg"
import radu from "../img/bord/radu.jpg"
import ana from "../img/bord/ana.jpeg"
import zaco from "../img/bord/zaco.jpg"
import ovi from "../img/bord/ovi.jpg"
import rox from "../img/bord/rox.jpg"
import genoveva2 from "../img/bord/genoveva_big.jpg"
import radu2 from "../img/bord/radu_big.jpg"
import ana2 from "../img/bord/ana_big.jpeg"
import zaco2 from "../img/bord/zaco_big.jpg"
import ovi2 from "../img/bord/ovi_big.jpg"
import rox2 from "../img/bord/rox_big.jpg"
import marian from "../img/directors/smecher.png"
import costi from "../img/directors/costi.jpeg"
import manea from "../img/directors/manea.jpg"
import mina from "../img/directors/mina.jpg"
import dutzu from "../img/directors/dutzu.png"
import denis from "../img/directors/denis.jpg"
import flavia from "../img/directors/flavia.jpeg"
import zh from "../img/directors/zh.jpeg"
import raluk from "../img/directors/raluca.jpg"



const bordImg = [
    {
        name: "Munteanu Maria-Genoveva",
        position: "Președinte",
        link: genoveva,
        profile: genoveva2,
        description: [
            "Salutare dragilor! Bine ați venit pe site-ul oficial LSAC! Și haideți să vă fac o mică introducere pe aici. Eu sunt Genoveva, aka șefa, și sunt președinte în cadrul acestei comunități de oameni minunați. ",
            "Sunt în anul 3 la Facultatea de Automatică și Calculatoare și pasiunea, dedicarea și dragostea față de oamenii din jur m-au făcut să ajung pe acest post.",
            "Ce mă ghidează spre mai departe? Energia. Primită de la voluntari și de la evenimentele în care punem suflet, ea mă face să continui și să construiesc alături de colegii mei, o asociație de voluntariat așa cum am văzut-o dintotdeauna: plină de viață, cu empatie, compasiune și mereu gata de noi oportunități. ",
            "LSAC este o familie pentru mine așa cum este și pentru cei mai mulți dintre membrii, Soo welcome to our family! 💙"
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/maryagenoveva.munteanu"}, {icon: "instagram", link: "https://www.instagram.com/maria_genovevaaa/"}],
        move: false,
        descType: "modal",
        inline: true
    },
    {
        name: "Munteanu Radu-Ștefan",
        position: "Vicepreşedinte Externe",
        link: radu,
        profile: radu2,
        description: [
            "Salut! Sunt Radu Ștefan Munteanu, student in al doilea an la CTI, Automatică și Calculatoare. In cadrul LSAC am fost director adjunct în departamentul de FR și acum sunt vicepreședinte pe relații externe.",
            "Îmi place adrenalina, să ajut colegii și să-i inspir să-și atingă potențialul maxim. Sunt muncitor, dedicat si ador ceea ce fac. Imi place sa  abordez viața cu un zâmbet mereu pe buze, mereu gata pentru noi aventuri și provocări."
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/radustefan.munteanu"}, {icon: "instagram", link: "https://instagram.com/rvdu.stefan?igshid=OGQ5ZDc2ODk2ZA=="}, {icon: "linkedin", link:"https://ro.linkedin.com/in/radu-%C8%99tefan-munteanu-6140a3278"}],
        move: true,
        descType: "modal",
        inline: true
    },
    {
        name: "Ciobanu Ana-Maria",
        position: "Vicepreședinte Interne",
        link: ana,
        profile:ana2,
        description: [
            "Bună! Sunt Ana, studentă la Facultatea de Automatica și Calculatoare din Iași și voluntară în liga studenților încă de la început.",
            "Pot spune despre mine că sunt o persoană sociabilă, muncitoare și determinată. În această perioadă în asociație am reușit să cunosc mulți oameni extraordinari și să ma dezvolt pe mine însămi.",
            "Sper să continui să aduc această liga cât mai sus și să realizez multe lucruri frumoase alături de colegii mei."
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/ana10maria5"}, {icon: "instagram", link: "https://www.instagram.com/ana.m.c26/?igshid=YmMyMTA2M2Y%3D"}],
        move: true,
        descType: "modal",
        inline: true
    },
    {
        name: "Zacordoneţ Andrei",
        position: "Secretar General",
        link: zaco,
        profile:zaco2,
        description: [
            "Salut! Mă numesc Andrei Zacordoneț și sunt student la Facultatea de Automatică și Calculatoare iar membru LSAC chiar din anul I. ",
            "În calitate de voluntar, atribute precum încrederea (atât în cei din jur cât și în mine), cordialitatea și exuberanța mi-au sporit eforturile aduse ligii, contribuind la dezvoltarea mea și a colegilor (prietenilor) mei. ",
            "Din oricare dintre ipostazele de viitor/actual/fost student citești acest text, te provoc sa fii în fiecare zi într-o continuă evoluție, concurând tot timpul cu ziua de ieri.Astfel, putem fiecare aduce o schimbare reală complexului ecosistem în care conviețuim."
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/andrei.zacordonet"}, {icon: "instagram", link: "https://www.instagram.com/andrei.zaco/?igshid=YmMyMTA2M2Y%3D"}],
        move: false,
        descType: "modal",
        inline: true
    },
    {
        name: "Cătăramă Ovidiu",
        position: "Cenzor",
        link: ovi,
        profile: ovi2,
        description: [
            "Salutare! Mă numesc Ovidiu şi sunt student în anul IV, CTI. Pe parcursul a trei ani universitari de voluntariat în cadrul LSAC m-am descoperit pe mine însămi ca fiind o fire prietenoasă si entuziasmată care găseşte plăcere în voluntariat şi în organizarea unor evenimente ce formează un cadru în care studenţii se pot dezvolta prin moduri diverse şi diferite faţă de cele pe care le oferă facultatea şi universitatea.",
            "Îmi propun ca în al patrulea an să ajut liga să se dezvolte şi mai mult şi să fiu alături de noua conducere pentru a-i ajuta în a duce LSAC către un alt nivel!",
            "Nu o să vă spun după ce citat mă ghidez eu în viaţă (\"Life is too short for bad coffee\"), dar o să vă zic că îmi doresc să ne distrăm la maxim împreună în timp ce oferim comunităţii noastre cele mai faine evenimente marca LSAC!💙",
            "#FamiliaLSAC"
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/ovidiu.catarama"}, {icon: "instagram", link: "https://www.instagram.com/ocatarama/"}, {icon: "linkedin", link: "https://www.linkedin.com/in/ovidiu-cătăramă-aa10b4236/"}],
        move: true,
        descType: "modal",
        inline: true
    },
    {
        name: "Apostol Roxana-Maria",
        position: "Cenzor",
        link: rox,
        profile: rox2,
        description: [
            "Bună! Sunt Roxana si sunt studentă în anul 4 la Facultatea de Automatică și Calculatoare din Iași.",
            "Fiecare zi este o nouă oportunitate de a mă dezvolta și de a-mi depăși limitele. Sunt pilot de avioane ultraușoare (ULM), iar zborurile mele reprezintă o sursă de adrenalină și libertate. În același timp,  timpul petrecut în natură și drumețiile mă încarcă cu energie pozitivă și mă ajută să mă reconectez cu frumusețea lumii din jur. 🏞️",
            "Pentru mine, LSAC nu este doar o organizație: este a doua mea familie. Aici, am avut bucuria de a întâlni oameni minunați care m-au inspirat și m-au motivat să devin cea mai bună versiune a mea. ✨",
            "La rândul meu, îmi doresc să ofer voluntarilor aceeași căldură și susținere pe care LSAC mi le-a oferit, pentru a-i face să se simtă cu adevărat ca parte din familia noastră. 💙"
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/roxana.maria141"}, {icon: "instagram", link: "https://www.instagram.com/wolfibleah/"}],
        move: true,
        descType: "modal",
        inline: true
    }
]

const itImg = [
    {
        name: "Ciotir Marian-Augustin",
        position: "Director",
        link: marian,
        profile: "",
        description: [
            "Salut! Eu sunt Marian și sunt student în anul II la Facultatea de Informatică din Iași, iar din LSAC fac parte încă din primele zile de facultate.",
            "Sunt o persoană sociabilă, deschisă și ambițioasă căreia îi place să afle lucruri noi. Pasiunile mele sunt în principal codatul, calculatoarele și jocurile video. Îmi place să mă implic în activități care mă ajută să mă dezvolt și să îmi îmbunătățesc abilitățile de comunicare și organizare."
            
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/marian.ciotir23/"}, {icon: "instagram", link: "https://www.instagram.com/marian_ciotir03/"}],
        move: false,
        descType: "",
        inline: true
    }
]

const designImg = [
    {
        name: "Buțco Dumitru-Constantin",
        position: "Director",
        link: costi,
        profile: "",
        description: [
            "Îmi văd adversaru doar când mă uit în oglindă."
        ],
        social: [{icon: "instagram", link: "https://www.instagram.com/dbcosti/"}],
        move: false,
        descType: "",
        inline: true
    },
    {
        name: "Manea Tudor",
        position: "Director Adjunct",
        link: manea,
        profile: "",
        description: [
            "Ma numesc Tudor, sunt student in anul 2 la IS, și sunt voluntar al LSAC pe departamentul de Design încă de când eram boboc. ",
            "Pot spune ca in cadrul ligii am găsit un “safe place” unde ma pot exprima liber și unde găsesc persoane cu care sa rezonez pe orice plan. Sunt pregatit sa ma dedic atat ligii, cât și tuturor voluntarilor, pentru a oferi aceasta idee de “safe place” mai departe."
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/tudor010203"}, {icon: "instagram", link: "https://www.instagram.com/manea.tudor/"}],
        move: true,
        descType: "",
        inline: true
    }
]

const hrImg = [
    {
        name: "Cojocaru Mina-Cristina",
        position: "Director",
        link: mina,
        profile: "",
        description: [
            "Bună tuturor! Eu sunt Mina și sunt directoarea departamentului de HR pentru anul acesta universitar.",
            "Sunt o persoană comunicativă, înțelegătoare și plină de energie. Îmi face plăcere să cunosc oameni noi, de aceea am și ales sa aplic la HR. Sunt o fire petrecăreață și abia aștept să ne distram împreună la următoarele petreceri pe care le vom organiza."
        ],
        social: [{icon: "instagram", link: "https://instagram.com/cristina_mina_?igshid=OGQ5ZDc2ODk2ZA=="}],
        move: false,
        descType: "",
        inline: true
    },
    {
        name: "Rusu Eduard-Ionuț",
        position: "Director Adjunct",
        link: dutzu,
        profile: "",
        description: [
            "Bună! Mă numesc Rusu Eduard, însă în general lumea mă cunoaște după \"Duțu\". Sunt o persoană înțelegătoare, perseverentă care își dorește să obțină mereu maximul dintr-o situație. Pasiunile mele sunt sportul, în special fotbalul, mașinile și calculatoarele."
        ],
        social: [{icon: "tiktok", link: "https://www.tiktok.com/@dutzurusu"}, {icon: "instagram", link: "https://www.instagram.com/dutu31/"}],
        move: true,
        descType: "",
        inline: true
    }
]

const frImg = [
    {
        name: "Chiriac Raluca-Flavia",
        position: "Director",
        link: flavia,
        profile: "",
        description: [
            "Bună! Sunt Flavia, studentă la Facultatea de Automatică și Calculatoare, Ingineria Sistemelor și în cadrul ligii sunt director adjunct în departamentul de FR.",
            "Despre mine pot spune că sunt o persoană dedicată și muncitoare, care dorește să se autodepășească și autoperfecționeze.",
            "Sunt o persoană sociabilă și destul de extrovertă, iar abilitatea mea de a interacționa cu ceilalți mă face să aștept cu nerăbdare să particip în noi aventuri care să contribuie la dezvoltarea mea și să creeze amintiri de neuitat."
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/ralucaflavia.chiriac"}, {icon: "instagram", link: "https://www.instagram.com/chiriacflavia/"}, {icon: "linkedin", link: "https://www.linkedin.com/in/flavia-chiriac-56852725a/"}],
        move: false,
        descType: "",
        inline: true
    },
    {
        name: "Boicu Denis-Teodor",
        position: "Director Adjunct",
        link: denis,
        profile: "",
        description: [
            "Bună eu sunt Denis, sunt persoana aia care ştie cele mai aleatorii lucruri despre mai orice lucru. Pe lângă asta mă văd ca fiind o persoană energică, ce se implică în orice activitate posibilă şi care e mereu pregătit să îi ajute pe alții în orice moment.",
            "Şi având in vedere că sunt cel de la care se cer mereu bani, ma voi numi Sugar Daddy-ul Ligii.",
            "Astfel pe final vă urez ce idolul meu, Mr Krabs, işi dorea mereu: \"Money money money\"."
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/denis.boicu.52/"}, {icon: "instagram", link: "https://instagram.com/den_ceva?igshid=ZDc4ODBmNjlmNQ=="}],
        move: true,
        descType: "",
        inline: true
    }
]

const prImg = [
    {
        name: "Zaharia Teodor-Ștefan",
        position: "Director",
        link: zh,
        profile: "",
        description: [
            "Motivația e un impuls, disciplina e un stil de viață",
            "Nu vă voi reține foarte mult cu texte de întinderi colosale pe care oricum nimeni nu le citește. Pot spune că citatul de mai sus mă descrie destul de bine, sunt o fire disciplinată și știu că în viață lucrurile valoroase nu se obțin deloc ușor. Unul din hobby-urile mele este sportul, în toate formele sale, asta pe lângă acela de a scrie.",
            "Zaharia Teodor-Ștefan, director PR, student anul III CTI"
        ],
        social: [{icon: "facebook", link: "https://www.facebook.com/zaharia.teodor.96/"}, {icon: "instagram", link: "https://www.instagram.com/zh_teo_st/"},{icon:"tiktok",link:"https://www.tiktok.com/@thechadone"}],
        move: false,
        descType: "",
        inline: true
    },
    {
        name: "Musteață Raluca-Elena",
        position: "Director Adjunct",
        link: raluk,
        profile: "",
        description: [
            "Buna tuturor! Numele meu este Musteață Raluca-Elena si pentru a mă descrie, aș spune că sunt o persoană extrovertită, intuitivă și nu în ultimul rând, empatică.",
            "Determinarea este o trăsătură puternică în personalitatea mea. Atunci când îmi propun un obiectiv, sunt hotărâtă să-l ating și sunt dispusă să depun efortul necesar pentru a reuși. Sunt deschisă cu cei din jurul meu, căutând întotdeauna să construiesc relații sincere și să creez un mediu plin de sprijin și înțelegere reciprocă. Îmi place să mă implic în comunitate și să aduc oamenii împreună pentru a se sprijini și a se bucura de compania unul celuilalt."
        ],
        social: [],
        move: true,
        descType: "",
        inline: true
    }
]
export default bordImg;
export { itImg, frImg, hrImg, prImg, designImg };