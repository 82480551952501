import React from "react";
import Countdown from "react-countdown";

function TimeComponent(props) {
    return <span className="time-wrap">
        <p>{props.timeUnit}</p>
        <p>{props.timeData}</p>
    </span>
}

const Completionist = () => <div className="time-bounds"><span className="event-finished">🥥Ne vedem anul viitor!🤩</span></div>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <Completionist />;
    } else {
        return (
            <div className="time-bounds">
                <TimeComponent timeUnit={"Zile"} timeData={days} />
                <TimeComponent timeUnit={"Ore"} timeData={hours} />
                <TimeComponent timeUnit={"Minute"} timeData={minutes} />
                <TimeComponent timeUnit={"Secunde"} timeData={seconds} />
            </div>
        );
    }
};

function EventCountdown() {
    return <div className="event-countdown-wrap">
        <Countdown date={'2022-11-21T18:00:00'} renderer={renderer} />
    </div>
}

export default EventCountdown;