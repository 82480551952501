import React from "react";
import SideBar from "../SideBar";
import bal from "../../img/events/Balul Bobocilor/bg.jpg";
import lsac from "../../img/events/Balul Bobocilor/lsac_event.png";
import logo from "../../img/events/Balul Bobocilor/logo.png";
import transition from "../../img/events/Balul Bobocilor/scandura.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

function EventJumbotron() {
    return <>
        <SideBar menuColor={"#cc933b"} barsColor={"orange"}/>
        <div className="container-event-jumbotron">
            <div className="jumbotron-bg-wrap">
                <LazyLoadImage className="jumbotron-bg" src={bal} alt="Balul bobocilor 2022" />
            </div>
            <div className="jumbotron-lsac-wrap">
                <LazyLoadImage className="jumbotron-lsac" src={lsac} alt="LSAC Bal" />
            </div>
            <div className="jumbotron-logo-wrap">
                <LazyLoadImage className="jumbotron-logo" src={logo} alt="Logo Bal" />
            </div>
            <div className="jumbotron-transition-wrap">
                <LazyLoadImage className="jumbotron-transition" src={transition} alt="plank" />
            </div>
        </div>
    </>
}

export default EventJumbotron;