import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faLinkedin, faTiktok } from "@fortawesome/free-brands-svg-icons";


function SocialMedia(props) {
    const icons = {
        "facebook" : faFacebook, 
        "instagram" : faInstagram, 
        "youtube" : faYoutube, 
        "linkedin" : faLinkedin,
        "tiktok": faTiktok
    };

    return (
        <div className="container-media">
            {props.icons.map((element, index) =>
                <FontAwesomeIcon 
                    onClick={()=> window.open(element.link, "_blank")} 
                    className={`social-media-icons ${props.colored ? "blueish" : ""}`}
                    key={index} 
                    icon={icons[element.icon]} size="2x" 
                />)}
        </div>
    );   
}

export default SocialMedia;