import React from "react";
import FlipCard from "./FlipCard";
import { flipcards } from "../../contentSite/pagesData";

function FlipCardSection() {
    return <div className="container-basic relative center">
        {flipcards.map((item, index) => {
            return <FlipCard key={index} data={item} overlayOn={true} hasSocial={false}/>
        })}
    </div>
}

export default FlipCardSection;