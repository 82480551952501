import React from "react";
import Member from "../Member";

function TeamRow(props) {
    const imgs = props.imgs;

    return <div className={`team-row ${props.move ? "team-row-secondary" : ""}`} >
        {imgs.map((element, index) => <Member
            key={index}
            member = {element}
        />)}
    </div>
}

export default TeamRow;