import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import funda from "../img/events/Christmas/props/funda.png"

function LiveMap() {
    return <div className="container-basic relative">
        <div className="shadow">
            <div className="map">
                <iframe title="map" height="500" width="100%" className="map-style" id="gmap_canvas" src="https://maps.google.com/maps?q=Bulevardul%20Tudor%20Vladimirescu%20%20C%C4%83min%20T19,%20Iasi&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </div>
            <div className="adress hide">
                <h2 className="space-top">Ne puteţi face o vizită la...</h2><br />
                <div className="space-left align-left">
                    <p><FontAwesomeIcon icon={faBuilding} size="2x" />&emsp; Iaşi, Bulevardul Tudor Vladimirescu, Cămin T19, Etajul 3</p>
                </div>
                <br /><h2 className="space-top">Sau ne puteţi trimite un mail la...</h2><br />
                <div className="space-left align-left">
                    <p><FontAwesomeIcon icon={faEnvelope} size="2x" />&emsp; lsaciasi@gmail.com</p>
                </div>
                {/*<span onClick={()=> window.location.replace("/wip")} className="btn offset" >Contact{/*<img className="funda" src={funda} alt="funda" />}</span>*/}
            </div>
        </div>
    </div>
}

export default LiveMap;