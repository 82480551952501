// Vanta JS CAUTA PE NET PT MAI MULTE ANIMATII
import NET from 'vanta/dist/vanta.net.min';
import HALO from 'vanta/dist/vanta.halo.min';
import FOG from 'vanta/dist/vanta.fog.min';
import DOTS from 'vanta/dist/vanta.dots.min';
import WAVES from 'vanta/dist/vanta.waves.min';
import RINGS from 'vanta/dist/vanta.rings.min';
import TOPOLOGY from 'vanta/dist/vanta.topology.min';
import GLOBE from 'vanta/dist/vanta.globe.min';
import p5 from 'p5';

const net = {
    type: NET,
    options: {
        color: 0x3f6cff, // normal: 0x3f6cff craciun: 0xd3d3d3
        backgroundColor: 0x001172, // normal: 0x001172 craciun: 1F2766
        spacing: 20,
        points: 15, //normal: 15 craciun: 20
        //maxDistance: 1 //nomaral: comentat craciun: 4
    }
}

const fog = {
    type: FOG,
    options: {
        highlightColor: 0xb400ff, //normal:0xb400ff craciun: 0x1F2766
        midtoneColor: 0xc00000, //normal: 0xc00000 craciun: 0xffffff
        lowlightColor: 0xff8200, //normal: 0xff8200 craciun: 0xffffff
        baseColor: 0xffebeb, //normal:  0xffebeb craciun: 0x1F2766
        blurFactor: 0.5,
        speed: 0.8,
        zoom: 0.7
    }
}

const dots = {
    type: DOTS,
    options: {
        backgroundColor: 0x0, //normal: 0x0 craciun: 0x1F2766
        color: 0xbf00ff, //normal: 0xbf00ff craciun: 0xffffff
        color2: 0xff03da, //normal: 0xff03da craciun: 0xffffff
        size: 3,
        spacing: 35,
        showLines: true
    }
}

const halo = {
    type: HALO,
    options: {
        backgroundColor: 0x653691, //normal: 0x653691 craciun: 0x1F2766
        baseColor: 0x76259e, //normal: 0x76259e craciun: 0xffffff
        size: 1.5,
        xOffset: 0.15,
        yOffset: 0.15
    }
}

const wave = {
    type: WAVES,
    options: {
        color: 0x21c61, //normal: 0x21c61 craciun: 0x1F2766
        zoom: 1,
    }
}

const rings = {
    type: RINGS,
    options: {
        backgroundColor: 0x42a,
    }
}

const globe = {
    type: GLOBE,
    options: {
        backgroundColor: 0x001172,
        color: 0xffffff,
        color2: 0xffffff,
    }
}

const topology = {
    type: TOPOLOGY,
    options: {
        color: 0xff00c3, // normal: 0xff00c3 craciun: 0xffffff
        backgroundColor: 0x0, //normaf: 0x0 craciun: 0x1F2766
        p5: p5
    }
}

export { net, fog, dots, wave, halo, rings, topology, globe };