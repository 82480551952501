import React from "react";
import { useState } from "react";
import Card from "./Card";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

function CardList(props) {
    const [description, setDescription] = useState(null);
    var [isModalOpen, setIsModalOpen] = useState(false);

    function openModal() {
        setIsModalOpen(true);
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        setIsModalOpen(false);
        document.body.style.overflow = 'unset';
    }

    return <>
        <div className="container-basic container-events">
            {props.eventsData.map((element, index) => <Card key={index} eventData={element} setDesc={setDescription} open={openModal} close={closeModal} />)}
        </div>
        <ReactModal
            isOpen={isModalOpen}
            shouldCloseOnOverlayClick={false}
            className="event-modal"
            overlayClassName="modal-overlay"
        >
            {description}
        </ReactModal>
    </>
}

export default CardList;