import React from "react";
import Quote from "./Quote";
import Member from "../Member";
import { useState } from "react";
import Gallery from "../Gallery";
import snow from "../../img/events/Christmas/props/snow_long.png";


function Content(props) {
    const [description, setDescription] = useState(null);
    const { quote, desc, background, directors, gallery } = props.details;

    return <>
        <div className="container-basic">
            <div className="photo-quote relative">
                <img src={background} alt="test" />
                <div className="overlay"></div>
                <Quote quote={quote} />
                {/*<img className="snow-long" src={snow} alt="snow" />*/}
            </div>
            <div className="directors-bounds">
                {directors.map((item, index) => <Member key={index} member={item} setter={setDescription} desc={description} />)}
                {description}
            </div>
        </div>
        <div className="container-basic dirty department-desc">
            <h1 className="department-heading">Rolul nostru</h1>
            <div className="department-text">
                {desc.map((item, index) => {
                    return <React.Fragment key={index}>
                        <p>{item}</p><br />
                    </React.Fragment>
                })}
            </div>
        </div>
        <div className="container-basic dirty lesspad-top">
            <Gallery gallery={gallery} />
        </div>
    </>
}

export default Content;