import React from "react";
import TeamMembers from "./TeamMembers";
import bordImg from "../../contentSite/bordImg";

function Team() {
    return <div className="container-basic">
        <h1 className="big-heading">ACEASTA ESTE CONDUCEREA ACTUALĂ</h1>
        <h3 className="medium-heading">Dacă ai întrebări sau încă nu te-am convins să vii la noi, poți să iei legătura cu cei din conducere pentru a afla mai multe informații.</h3>
        <TeamMembers amount={6} bord={bordImg} row1move={false} row2move={true}/>
    </div>
}

export default Team;