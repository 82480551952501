import React from "react";
import AnimationComponent from "../AnimationComponent";
import SponsorList from "../sponsorsComponents/SponsorList";
import Footer from "../Footer";
import { sponsors } from "../../contentSite/pagesData";

function Sponsors() {
    return <>
        <AnimationComponent data={sponsors} />
        <SponsorList />
        <Footer color={null} />
    </>
}

export default Sponsors;