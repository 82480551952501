import it from "../img/logos/it.png";
import design from "../img/logos/design.png";
import pr from "../img/logos/pr.png";
import hr from "../img/logos/hr.png";
import fr from "../img/logos/fr.png";
import logo from "../img/logos/logo.png";
import sponsori from "../img/logos/sponsori.png";
import events_logo from "../img/logos/events.png";
import welcome from "../img/welcome.jpg";
import scope from "../img/scope.jpg";
import fr_grup from "../img/departments/fr/grup.jpeg";
import hr_grup from "../img/departments/hr/grup.JPG";
import design_grup from "../img/departments/design/grup.JPG";
import pr_grup from "../img/departments/pr/grup.JPG";
import it_grup from "../img/departments/it/grup.JPG";
import { itImg, designImg, frImg, hrImg, prImg } from "./bordImg";
import { net, halo, dots, wave, fog, rings, topology, globe } from "./animations";

import foto1it from "../img/gallery/it/membrii1.jpg";
import foto2it from "../img/gallery/it/membrii2.jpg";
import foto3it from "../img/gallery/it/membrii3.jpg";

import foto1pr from "../img/gallery/pr/membrii1.jpg";
import foto2pr from "../img/gallery/pr/membrii2.jpg";
import foto3pr from "../img/gallery/pr/membrii3.jpg";
import foto4pr from "../img/gallery/pr/membrii4.jpg";

import foto1hr from "../img/gallery/hr/membrii1.jpg";
import foto2hr from "../img/gallery/hr/membrii2.jpg";
import foto3hr from "../img/gallery/hr/membrii3.jpg";
import foto4hr from "../img/gallery/hr/membrii4.jpg";

import foto1design from "../img/gallery/design/membrii1.jpg";
import foto2design from "../img/gallery/design/membrii2.jpg";

import foto1fr from "../img/gallery/fr/membrii1.jpg";
import foto2fr from "../img/gallery/fr/membrii2.jpg";
import foto3fr from "../img/gallery/fr/membrii3.jpg";

import christmas from "../img/events/banners/christmas.jpg";
import hallo from "../img/events/banners/haloween.jpg";
import hallo2 from "../img/events/banners/haloween2.jpg";
import itm from "../img/events/banners/itm.png";
import lan from "../img/events/banners/lan.jpeg";
import prezentare from "../img/events/banners/prezentare.jpg";

import dev from "../img/events/DevMe/dev.png";
import ITM from "../img/events/ITM/itm.jpg";
import LAN from "../img/events/LAN/lan.png";
import Christmas from "../img/events/Christmas/christmas.png";
import lim from "../img/events/LIM/lim.png";
import bal from "../img/events/Balul Bobocilor/poster.png";
import th from "../img/events/TH/th.jpg";
import gaud from "../img/events/gaudeamus/gaud.jpg";
import festudis from "../img/events/festudis/festudis.jpg";
import studentiada from "../img/events/studentiada/stud.png";


const departments = [
    {
        name: "it",
        description: "Departamentul de IT",
        logo: it,
        animation: wave,
        content: {
            quote: "\"Omul cât trăiește învață.\"",
            desc: [
                "Departamentul de IT este responsabil de aspectele informatice ale asociației și menținerea acestora la un nivel corespunzător unei facultăți de profil. Fiecare membru își aduce aportul în menținerea tuturor sistemelor informatice funcționale și disponibile atât membrilor din ligă, cât și persoanelor externe. Voluntarii sunt responsabili de programare, arhitectură hardware și mentenanța site-ului ligii și cele ale evenimentelor aferente acestuia. Efortul principal al acestui departament este acela de a menține la cel mai înalt nivel prezența ligii în mediul digital. Tot în cadrul acestui departament, membrii ligii au ocazia de a-și dezvolta cunoștințele în domeniu prin sesiuni de training și knowledge sharing.",
                "Training-urile realizate în cadrul departamentului te pot învăţa lucruri noi, vei cunoaşte oameni noi şi vei progresa în acest domeniu al informaticii în funcţie de implicarea ta in el. Coordonatorul, ca şi ceilalţi coordonatori din celelalte departamente, va fi mereu acolo să vă îndemne şi ajute când aveţi nevoie de ajutor, deci nu ezitaţi să spuneţi cănd vă împotmoliţi.",
                "Nu va fi mereu de lucru în acest departament, însă încurajăm voluntarii să se implice și în alte părți sau dacă doresc, să vină cu o idee/creație în ajutorul asociației şi a evenimentelor susținute de aceasta."
            ],
            background: it_grup,
            directors: itImg,
            gallery: [
                {
                    original: foto1it,
                    thumbnail: foto1it,
                },
                {
                    original: foto2it,
                    thumbnail: foto2it,
                },
                {
                    original: foto3it,
                    thumbnail: foto3it
                }
            ]
        },
        footerColor: ""//"#021c61"
    },
    {
        name: "fr",
        description: "Departamentul de Relaţii Externe",
        logo: fr,
        animation: halo,
        content: {
            quote: "\"Nu te descuraja de eşec. Poate fi o experienţă pozitivă.\"",
            desc: [
                "Departamentul de Relaţii Externe asigură căutarea de sponsorizări pentru activitățile și evenimentele ligii care nu pot fi susținute din fonduri proprii. Acesta presupune contactarea unor companii sau instituții care pot oferi sprijin în realizarea activităților ligii și încercarea de a le convinge de beneficiile implicării într-un astfel de parteneriat.",
                "Fundraising-ul ne ajută să învățăm cum să discutăm și cu alți oameni pe lângă profesori sau studenți/prieteni, prin intermediul acestui departament putând lua contact cu mediul de afaceri încă din primii ani de facultate. În plus, strângerea de fonduri reprezintă un exercițiu foarte bun pentru dezvoltarea unor aptitudini esențiale pentru un viitor job, deoarece este foarte important să știi cum să interacționezi cu oameni importanți și să reușești să-i faci să se alăture unui proiect inițiat de tine.",
                "Să strângi fonduri nu e uşor, nu tot timpul poţi avea succes, însă e o bună practică pentru viitor. Chiar dacă firmele uneori te pot refuza, important e să nu iţi pierzi răbdarea şi speranţa."
            ],
            background: fr_grup,
            directors: frImg,
            gallery: [
                {
                    original: foto1fr,
                    thumbnail: foto1fr,
                },
                {
                    original: foto2fr,
                    thumbnail: foto2fr,
                },
                {
                    original: foto3fr,
                    thumbnail: foto3fr
                }
            ]
        },
        footerColor: ""//"#653691"
    },
    {
        name: "hr",
        description: "Departamentul de Relaţii Interne",
        logo: hr,
        animation: dots,
        content: {
            quote: "\"Norocul îl află doar cel ce nu rămâne locului.\"",
            desc: [
                "Dacă privim liga noastră ca fiind un mecanism complex, putem spune cu ușurință că departamentul de Relaţii Interne este echivalentul rotițelor care stau la baza acestuia.",
                "Departamentul de Relaţii Interne asigură funcționarea în parametri normali a ligii, prin încurajarea creării relațiilor de prietenie, organizarea variatelor evenimente cu rol recreativ, recrutarea și asigurarea acomodării membrilor noi și organizarea team building-urilor. Deși toți voluntarii ligii sunt promți atunci când este nevoie de ei, membrii de la Relaţii Interne se remarcă în asigurarea bunei desfășurări a evenimentelor, fiind dispuși la orice oră să se mobilizeze pentru a acoperi necesarul de resursă umană.",
                "Membrii departamentului se asigură în orice moment ca fiecare membru să fie la locul potrivit și ca mediul în care acesta lucrează să fie unul propice în atingerea potențialului maxim în timp util."
            ],
            background: hr_grup,
            directors: hrImg,
            gallery: [
                {
                    original: foto1hr,
                    thumbnail: foto1hr,
                },
                {
                    original: foto2hr,
                    thumbnail: foto2hr,
                },
                {
                    original: foto3hr,
                    thumbnail: foto3hr
                }
                ,
                {
                    original: foto4hr,
                    thumbnail: foto4hr
                }
            ]
        },
        footerColor: ""//"#000"
    },
    {
        name: "pr",
        description: "Departamentul de Relaţii Publice",
        logo: pr,
        animation: topology,
        content: {
            quote: "\"Într-o lume în care poți fi ce vrei, fii bun.\"",
            desc: [
                "Poate unul dintre cele mai interactive departamente, PR este despre cuvintele frumoase care descriu evenimente și mai frumoase, despre cum la sfârșitul zilei ne adunăm gândurile și împărtășim cele mai interesante întâmplări și aventuri care ne-au umplut ziua în cadrul ligii.",
                "Acest departament urmărește prezentarea evenimentelor ce au loc la nivelul familiei LSAC și promovarea acestora prin participarea activă a membrilor dornici să împărtășească publicului larg proiectele și evenimentele pe care le organizăm. Mai mult, tot în cadrul acestui departament, membrii au oportunitatea să-și dezvolte abilitățile de public speaking sau pe cele scriitoricești, având adesea sarcini ce constau în înșiruirea ideilor personale referitoare la anumite activități desfășurate în calitate de voluntar al ligii."
            ],
            background: pr_grup,
            directors: prImg,
            gallery: [
                {
                    original: foto1pr,
                    thumbnail: foto1pr,
                },
                {
                    original: foto2pr,
                    thumbnail: foto2pr,
                },
                {
                    original: foto3pr,
                    thumbnail: foto3pr
                }
                ,
                {
                    original: foto4pr,
                    thumbnail: foto4pr
                }
            ]
        },
        footerColor: ""//"#000"
    },
    {
        name: "design",
        description: "Departamentul de Design",
        logo: design,
        animation: fog,
        content: {
            quote: "\"Design-ul este inteligența transpusă în artă\"",
            desc: [
                "Departamentul de Design reprezintă în adevăratul sens al cuvântului imaginea ligii.",
                "Acesta se ocupă cu tot ceea ce ține de media, de la poze din cadrul evenimentelor până la imagini destinate postărilor și videoclipuri care să ne amintească de cele mai frumoase momente petrecute împreună. Departamentul este alcătuit din oameni activi, cu o imaginație și un simț estetic nemaipomenit. După cum probabil vă dați seama, munca depusă de acești voluntari este colosală și esențială. De la îmbinatul culorilor până la alegerea celor mai bune poze, acest departament reușește de fiecare dată să ofere cele mai de bun gust resurse media care, combinate atent cu texte potrivite, duc la postări de succes.",
                "Membrii acestui departament depun mult efort și își rezervă câteva ore mereu pentru a crea materiale de o calitate excepțională, plăcut vizuale. Cum în acest departament intra și echipa de media, ei sunt artiștii noștri fotografi ce urmăresc totul cu atenție pentru a surprinde momente unice și de neuitat."
            ],
            background: design_grup,
            directors: designImg,
            gallery: [
                {
                    original: foto1design,
                    thumbnail: foto1design,
                },
                {
                    original: foto2design,
                    thumbnail: foto2design,
                },
            ]
        },
        footerColor: ""//"#d42a7a"
    },
]

const home = {
    name: "",
    description: "Liga Studenţilor Facultăţii de Automatică şi Calculatoare din Iaşi",
    logo: logo,
    animation: net
}

const events = {
    name: "events",
    description: "",
    logo: events_logo,
    animation: globe
}

const sponsors = {
    name: "sponsors",
    description: "În această secțiune puteți vedea sponsorii și partenerii noștri cei mai frecvenți.",
    logo: sponsori,
    animation: rings
}

const flipcards = [
    {
        front: "NOI SUNTEM LSAC",
        back: [
            "Liga Studenților Facultății de Automatică și Calculatoare (LSAC) s-a înființat în anul 2005, fiind o organizație studențească, non-guvernamentală, non-profit și apolitică ce dorește să le ofere tuturor o studenție cât mai frumoasă prin intermediul activităților ce sunt organizate anual.",
            "De asemenea, asociația noastră apără și respectă drepturile și interesele tuturor studenților, păstrând o strânsă legătură cu facultatea și membrii acesteia."
        ],
        img: welcome
    },
    {
        front: "SCOPUL NOSTRU",
        back: [
            "De la voluntariat, la seri de jocuri, Liga Studenților de la Facultatea de Automatică și Calculatoare își propune să aducă studenții împreună. Liga este cu și despre studenți, despre a crea prietenii, legături strânse între oameni inițial colegi sau chiar străini.",
            "",
            "Pe lângă implicarea în diferite oportunități din comunitatea ieșeană, ea reprezintă un real suport pentru studenți. Este despre a oferi și primi ajutor și nu în ultimul rând, despre dezvoltare personală și profesională."
        ],
        img: scope
    },
]

const icons = [
    { icon: "facebook", link: "https://www.facebook.com/lsac.is" },
    { icon: "instagram", link: "https://www.instagram.com/lsaciasi" },
    { icon: "youtube", link: "https://www.youtube.com/c/LSACIasi" },
    { icon: "tiktok", link: "https://www.tiktok.com/@lsaciasi" }
];

const sideBarLinksDep = [
    { route: "it", display: "IT"},
    { route: "hr", display: "HR"},
    { route: "fr", display: "FR"},
    { route: "pr", display: "PR"},
    { route: "design", display: "Design"}
];

const sideBarLinks = [
    { route: "events", display: "Evenimente"},
    { route: "sponsors", display: "Sponsori"},
];

const ourEventsData = [
    {
        name: "DevelopMe",
        image: dev,
        description: [
            "În 2022 a avut loc loc prima ediție a taberei de vară pentru liceeni „DevelopMe(DevMe)”. Tabăra își propune să unească elevi comunicativi, dornici de a trăi noi experiențe și de a pătrunde în mediul universitar ieșean înainte de a-și alege o facultate.",
            "„DevMe” se desfășoară pe parcursul a o săptămână, timp în care 30 de participanți vor simți din plin spiritul studențesc. Pe lângă programul creat special, cu ore de dimineață, cursuri de luni până vineri, participanții vor avea parte de nelipsita mâncare de la cantină și nu în ultimul rând de Examenul Final.",
            "Principalele activități care se vor desfășura în cadrul taberei sunt cursuri din diverși ani universitari, dar și diferite activități menite să ofere o experiență completă de student. Printre abilitățile pe care ne propunem ca liceenii să le dobândească se regăsesc: comunicare, lucrul în echipă, dar și individual, creativitatea, încrederea în sine și spiritul organizatoric."
        ],
        hasPage: false
    },
    {
        name: "IT Marathon",
        image: ITM,
        description: [
            "În 2022 s-a desfășurat a X-a ediție a concursului “IT Marathon”, eveniment ce a crescut în fiecare an atât ca număr de participanți, cât și ca număr de secțiuni. Concursul, care a ajuns unul de nivel național, se adresează studenților și elevilor de liceu pasionați de IT.",
            "Secțiunile din cadrul concursului se desfășoară online, sub forma unui maraton de 12 ore pentru fiecare nișă, acestea fiind: Desktop&Mobile Applications, Web Development și Design. Participanții vor lucra în echipe de maxim 3 persoane. Aceștia trebuie să răspundă unei teme propuse de către companiile partenere în colaborare cu reprezentanții Facultății de Automatică și Calculatoare Iași, pe care vor trebui să o abordeze într-un mod cât mai inovativ.",
            "Pe lângă domeniile menționate, evenimentul cuprinde și o categorie destinată elevilor de liceu, Junior Dev, ce va avea loc în incinta Facultății de Automatică și Calculatoare Iași. Caştigătorii sunt desemnaţi de un juriu format din reprezentanţi ai companiilor partenere, dar şi ai facultăţii, urmărindu-se originalitatea şi calitatea soluţiilor."
        ],
        hasPage: false,
        route: ""
    },
    {
        name: "LAN Party",
        image: LAN,
        description: [
            "Lan Party Local Edition este o competiție ce reunește studenții pasionați atât de jocurile pe calculator și console, cât și pe cei interesați de domeniul IT și tehnologie.",
            "Printre obiective se numără crearea unei tradiții care să motiveze participarea cât mai multor concurenți la fiecare ediție și oferirea posibilității studenților de a-și demonstra aptitudinile la jocuri precum League of Legends și Counter-Strike.",
            "LSAC Iaşi are o experiență de 13 ani în organizarea acestui tip de evenimente, creând astfel o comunitate strânsă în jurul unui subiect îndrăgit de majoritatea studenților și tinerilor.",
            "Ediţiile din 2021 şi 2022 au avut loc în format online, în luna martie, însă înainte de pandemie, Iaşiul avea parte de una dintre cele mai distractive LAN Party-uri organizate fizic. Ca tradiţie, LSAC Iaşi trimite echipele câștigătoare la jocurile propuse, la etapa naţională de LAN Party, organizată de LSAC Bucureşti."
        ],
        hasPage: false,
        route: ""
    },
    {
        name: "Let's Start A Christmas Story",
        image: Christmas,
        description: [
            "În preajma sărbătorilor de iarnă, spiridușii din LSAC aduc speranță și zâmbete prin evenimentul caritabil \"Let's Start A Christmas story\". De doi ani reușim să scriem povești magice alături de părintele Dan Damaschin și Asociația \"Glasul Vieții\".",
            "Îmbinăm timpul de calitate petrecut în familia LSAC cu faptele bune și căldura pe care o simțim dăruind. Evenimentul cuprinde mai multe momente, de la împodobirea bradului și schimbul de cadouri pe care îl facem în cadrul evenimentului \"Secret Santa\", la prepararea prăjiturilor, seară de film între prieteni și chiar un livestream caritabil. Mereu ne mobilizăm cu toții pentru a simți spiritul Crăciunului și pentru a lumina sărbătorile copiilor care au nevoie de noi!",
        ],
        hasPage: false,
        route: ""
    },
    {
        name: "LSAC Internship Month",
        image: lim,
        description: [
            "Internship Month este unul dintre cele mai recent introduse proiecte marca LSAC. Acesta se desfășoară începând cu anul 2021, în luna noiembrie și are ca scop organizarea unor sesiuni săptămânale de training și mentorat pentru fiecare departament al ligii.",
            "Această nouă inițiativă a luat naștere ca urmare a conștientizării nevoii studenților de a-și dezvolta abilități extracuriculare, necesare atât pe piața muncii, cât și în viața personală. Sesiunile de training au ca scop consolidarea cunoștințelor legate de educație financiară și business, autocunoaștere și dezvoltare personală, dezbateri și public speaking."
        ],
        hasPage: false,
        route: ""
    },
    {
        name: "Treasure Hunt",
        image: th,
        description: [
            "LSAC Iași a organizat în 2021 prima ediție a TreasureHunt, eveniment ce și-a atins cu succes scopul, anume crearea și întărirea legăturilor între bobocii asociației și voluntarilor mai vechi.",
            "Încă o dată, lucrul în echipă și creativitatea membrilor ligii au dus la dezvoltarea unui mediu prielnic în care studenții să cunoască atât persoane noi, cât și pe sine și în care să dezvolte sau să dobândească abilitatea de a rezolva probleme într-un timp limitat.",
            "Pe lângă eforturile intelectuale depuse, studenții au fost provocați și fizic, întrucât probele au avut loc pe tot teritoriul campusului. Plasarea acestui eveniment a fost de asemenea aleasă strategic cât mai aproape de începutul anului universitar pentru ca studenții să se acomodeze cu întreg campusul și cu orașul, mulți dintre aceștia venind din alte parți ale țării."
        ],
        hasPage: false,
        route: ""
    },
    {
        name: "Balul Bobocilor",
        image: bal,
        description: [
            "Evenimentul mult așteptat de proaspăt studenții de la Facultatea de Automatică și Calculatoare își face revenirea cu forțe noi, idei noi și desigur, oameni noi.",
            "Balul Bobocilor a fost organizat până în 2020, în fiecare an, de către LSAC, reușind să aducă împreună comunitatea studențească. Focusându-se pe abilitățile artistice, imaginația, creativitatea și spontaneitatea participanților, competiția reprezintă unul dintre evenimentele cele mai îndrăgite.",
            "Câștigătorii sunt desemnați în urma unor probe specifice, de către un juriu format din reprezentanți ai facultății, ai studenților și nu în cele din urmă, ai partenerilor care oferă suport evenimentului."
        ],
        hasPage: true,
        route: "/bal2022"
    }

];

const otherEventsData = [
    {
        name: "Gaudeamus",
        image: gaud,
        description: [
            "Festivalul Artei și Creației Studențești „Gaudeamus“ este un proiect implementat de Casa de Cultură a Studenților din Iași, organizat împreună cu asociațiile și ligile studențești. Prin acest proiect, de 8 ani, se dorește scoaterea în evidență a potențialului tinerilor în realizarea evenimentelor culturale.",
            "Evenimentul constă în formarea de alianțe între 2 asociații sau ligi studențești și prezentarea unui program artistic. Acesta cuprinde un total de maximum 10 momente artistice, care se încadrează în limita a 120 de minute. În urma unei jurizări realizate de referenții culturali ai CSS, dar și a prezenței publicului, în sală, la spectacol, se stabilește clasamentul.",
            "LSAC are o experiență de ? ani în participarea la acest eveniment, colaborând pe parcursul acestor ani cu asociații precum: ASAI, A.S.A.HGIM etc."
        ]
    },
    {
        name: "Festudis",
        image: festudis,
        description: [
            "FEstudIS este un festival studenţesc organizat de Casa de Cultură a Studenţilor din Iasi în colaborare cu aproximativ 30 de asociaţii studenţesti. Sloganul acestui festival este:",
            "“FEstudIS, singura sesiune aşteptată!”",
            "FEstudIS a luat naştere în anul 2001 din dorinţa studenţilor de a-şi putea exprima în diferite moduri, spiritul competitiv şi pofta de distracţie. Are ca scop principal încurajarea participării active la evenimentele organizate de ligi, asociaţii şi organizaţii studenţeşti oferind tinerilor ieşeni oportunitatea de a se implica în această sesiune artistică.",
            "Festivalul, conceput de studenţi pentru studenţi, a devenit tradiţional pentru viața studenţească a Iaşiului şi reuneşte an de an studenţi din toate centrele universitare. Timp de 7 zile oraşul nostru este cuprins de o frenezie studenţească care schimba pulsul acestuia, chiar şi în unul dintre cele mai mari centre universitare din ţara. Prin numărul impresionant de participanţi şi prin varietatea evenimentelor organizate, FEstudIS a devenit o tradiţie dusă mai departe anual cu seriozitate şi entuziasm. Este ştiut faptul că încă din 2001 studenţii ieşeni aşteaptă o singură sesiune, cea de la începutul lunii mai."
        ]
    },
    {
        name: "Studentiada",
        image: studentiada,
        description: [
            "Studențiada este unul dintre cele mai mari festivaluri studențești, organizat la nivel național de către Uniunea Națională a Studenților din România(UNSR) și de către alte organizații partenere.",
            "Festivalul a luat naștere acum câțiva ani, având ca target studenții din cadrul a trei universități: Universitatea Tehnică „Gheorghe Asachi”, Universitatea „Alexandru Ioan Cuza” și Universitatea de Științele Vieții „Ion Ionescu de la Brad”.",
            "Studențiada este destinată studenților, fiind prilejul perfect pentru a participa la numeroasele activități care sunt organizate. De la campionate sportive și seri de film, la dezbateri și training-uri, tinerii sunt invitați să se bucure de reînvierea la viața studențească."
        ]
    }
]

const eventsGallery =  [
    {
        original: prezentare,
        thumbnail: prezentare
    },
    {
        original: hallo,
        thumbnail: hallo,
    },
    {
        original: christmas,
        thumbnail: christmas,
    },
    {
        original: hallo2,
        thumbnail: hallo2
    },
    {
        original: itm,
        thumbnail: itm,
    },
    {
        original: lan,
        thumbnail: lan,
    }
];

export default departments;
export { home, sponsors, events, eventsGallery, flipcards, icons, sideBarLinks, sideBarLinksDep, ourEventsData, otherEventsData };