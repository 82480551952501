import React from "react";
import AnimationComponent from "../AnimationComponent";
import Footer from "../Footer";
import CardList from "../eventsComponents/CardList";
import Gallery from "../Gallery";
import HeaderText from "../eventsComponents/HeaderText";
import { events, eventsGallery, ourEventsData, otherEventsData } from "../../contentSite/pagesData";
import ChristmasLights from "../ChristmasLights";

function Events() {
    return <>
        <AnimationComponent data={events} />
        {/* <ChristmasLights /> */}
        <Gallery gallery={eventsGallery} thumbnails={false} fullscreen={false} />
        <HeaderText text={"Evenimente organizate de noi"} />
        <CardList eventsData={ourEventsData} />
        <HeaderText text={"Evenimente la care participăm anual"} />
        <CardList eventsData={otherEventsData} />
        <Footer />
    </>
}

export default Events;