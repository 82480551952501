import React from "react";
import Footer from "../Footer";
import EventJumbotron from "../eventComponents/EventJumbotron";
import EventContent from "../eventComponents/EventContent";

function Event() {
    
    document.body.style.overflow = 'unset';

    return <>
        <EventJumbotron />
        <EventContent />
        <Footer color={"#cc933b"}/>
    </>
}

export default Event;