import React from "react";
import funda from "../img/events/Christmas/props/funda.png"


function WelcomeMessage(props) {
    return (
        <div className="container-message">
            <div className="welcome-section">
                <img className="responsive-img center-logo" src={props.logo} alt="logo" />
                <p className="message center-responsive">{props.description}</p> 
            </div>
            <div className="welcome-section">
                {/* <img onClick={()=> window.location.replace("/event") } className="welcome-event-img" src={event_logo} alt="event logo" /> */}
                <button className="redir-btn offset" onClick={() => window.open("https://redirectioneaza.ro/lsaciasi", "_blank")} >Redirecționare 3.5% {/*<img className="funda" src={funda} alt="funda" />*/}</button>
            </div>
        </div>
    );
}

export default WelcomeMessage;