import React from "react";
import sponsors from "../../contentSite/sponsors";
import Sponsor from "./Sponsor";

function SponsorList() {
    return <div className="container-basic center">
        {sponsors.map((item, index) => {
            return <Sponsor key={index} details={item} />
        })}
    </div>
}

export default SponsorList;