import { React, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link, Outlet } from "react-router-dom";
import logo from "../img/logos/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { sideBarLinksDep, sideBarLinks } from "../contentSite/pagesData";
import Snowfall from 'react-snowfall';



function SideBar(props) {
    const [dropdown, setDropdown] = useState(null);

    function toggleDropdown() {
        if (dropdown === null)
            setDropdown(<div className="bm-wrap">
                {sideBarLinksDep.map((element, index) => <Link key={index} className="bm-item bm-dropdown-item" to={"/" + element.route}>{element.display}</Link>)}
            </div>);
        else
            setDropdown(null);
    }

    var styles = {
        bmMenu: {
            background: props.menuColor
        },
        bmBurgerBars: {
            background: props.barsColor
        }
    }

    return <>
        {/* <Snowfall
            style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                zIndex: '9999'
            }}
        /> */}
        <Menu styles={styles} right>
            <img className="bm-logo" src={logo} alt="logo" />
            <Link to="/">Pagina principală</Link>
            {/* <Link to="/event">⭐Balul bobocilor⭐</Link> */}
            <span onClick={toggleDropdown} style={{ cursor: "pointer" }}>Departamente <FontAwesomeIcon icon={faCaretDown} /></span>
            {dropdown}
            {sideBarLinks.map((element, index) => <Link key={index} to={"/" + element.route}>{element.display}</Link>)}
        </Menu>
        <Outlet />
    </>


}

export default SideBar