import React from "react";
import frunze from "../../img/events/Balul Bobocilor/frunze.png";
import nisip from "../../img/events/Balul Bobocilor/nisip.png";
import EventCountdown from "./EventCountdown";
import EventInfo from "./EventInfo";
import EventParticipants from "./EventParticipants";
import EventWinners from "./EventWinners";

function EventContent() {
    return <div className="event-content-wrap">
        <div>
            <img className="event-leaf-prop" src={frunze} alt="frunze" />
        </div>
        <EventCountdown />
        <EventInfo />
        <EventParticipants />
        <EventWinners />
        <div className="event-footer-transition">
            <img src={nisip} alt="Nisip" />
        </div>
    </div>
}

export default EventContent;