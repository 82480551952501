import React from "react";
import { balPerechi } from "../../contentSite/eventsData";
import Participants from "./Participants";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function EventParticipants() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 669 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 669, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return <>
        <div className="participants-header">
            <span>Perechile</span>
        </div>
        <Carousel responsive={responsive} swipeable={true} containerClass="react-carousel" infinite={true} ssr={true} >
            {balPerechi.map((data, index) => <Participants key={index} data={data} />)}
        </Carousel>
    </>

}

export default EventParticipants;