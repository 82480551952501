import React from "react";
import logo from "../img/logos/logo.png";

function Footer(props) {
    const year = new Date().getFullYear();

    return <div className="container-basic footer" style={{background: props.color}}>
        <div className="footer-section">
            <div>
                <h3>PENTRU MAI MULTE DETALII</h3><br />
                <h4>Munteanu Maria-Genoveva</h4>
                <h4>Telefon: 0787 610 893</h4>
                <h4>Email: lsaciasi@gmail.com</h4>
            </div>
        </div>
        <div className="footer-section">
            <img className="" src={logo} alt="logo" />
        </div>
        <div className="footer-section">
            <p>Copy © {year} All rights reserved | This website was made by <a style={{textDecoration: "none", color: "#D45F15"}} href="https://www.linkedin.com/in/constantin-ciobanu-26554720a/">Costi</a>.</p>
        </div>
    </div>
}

export default Footer;