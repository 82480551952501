import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function Card(props) {
    const { name, image, description, hasPage, route } = props.eventData;

    function desc() {
        return <div className="container-desc" style={{backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundPositionY: "25%"}}>
            <span style={{position:"absolute", top: "0", left: "0", zIndex: "-1", backgroundColor: "rgba(0, 0, 0, 0.5)", width: "100%", height: "110%"}}></span>
            <span className="close-button adjust" onClick={() => props.close()}><FontAwesomeIcon icon={faXmark} size="2x" /></span>
            {description.map((element, index) => {
                return <React.Fragment key={index}>
                    <p >&emsp;{element}</p><br />
                </React.Fragment>
            })}
            {hasPage ? <div className="btn-container"><Link className="btn-event-page" to={route}><p>⭐Pagina evenimentului⭐</p></Link></div> : ''}
        </div>
    }

    return <div className="card-event" onClick={() => {
        props.setDesc(desc);
        setTimeout(props.open, 100);
    }}>
        <LazyLoadImage src={image} alt={name} />
    </div>
}

export default Card;