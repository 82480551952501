import React from "react";
import { balCastigatori } from "../../contentSite/eventsData";
import Winners from "./Winners";

function EventWinners() {
    return <>
        <div className="participants-header">
            <span>Câştigătorii</span>
            <p style={{fontSize: "40px"}}>🎉</p>
        </div>
        <div className="polaroid-collection">
            {balCastigatori.map((data, index) => <Winners key={index} data={data} />)}
        </div>
    </>

}

export default EventWinners;