import React, { useState, useEffect, useRef } from "react";
import SocialMedia from "./sponsorsComponents/SocialMedia";
import WelcomeMessage from "./WelcomeMessage";
import SideBar from "./SideBar";
import { icons } from "../contentSite/pagesData";

function AnimationComponent(props) {
    const { name, description, logo, animation, content, footerColor } = props.data;
    const { type, options } = animation;
    const [vantaEffect, setVantaEffect] = useState(0);

    const myRef = useRef(null)

    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(type({
                el: myRef.current,
                mouseControls: true,
                touchControls: false,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                ...options
            }))
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy()
        }
    }, [vantaEffect])

    return <>
        <SideBar menuColor={footerColor} />
        <div className="container-animation" ref={myRef}>
            <SocialMedia
                colored={false}
                icons={icons}
            />
            <WelcomeMessage
                logo={logo}
                description={description}
            />
    </div>
    </>

}

export default AnimationComponent;