import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Winners(props) {
    const { imagine, nume, premiu, decoration } = props.data;

    const style = {
        boxShadow: `10px 10px 150px 10px ${decoration} inset, 3px 3px 6px black`
    }

    return <div className="winner-frame" style={style}>
        <div className="polaroid-photo-bounds">
            <LazyLoadImage src={imagine} alt={nume} />
        </div>
        <span className="participants-name">{nume}</span><br />
        <span className="participants-name">{premiu}</span>
    </div>
}

export default Winners;