import React from "react";
import AnimationComponent from "../AnimationComponent";
import News from "../newsSection/News";
import Team from "../teamSection/Team";
import LiveMap from "../LiveMap";
import Footer from "../Footer";
import { home } from "../../contentSite/pagesData";
import FlipCardSection from "../flipcardSection/FlipCardSection";
import ChristmasLights from "../ChristmasLights";
import CurrentEvent from "../CurrentEvent/CurrentEvent";

function Home() {
    return (
        <>
            <AnimationComponent data={home} />
            {/* <ChristmasLights /> */}
            {/* <News /> */}
            {/* <Placeholder /> */}
            {/*<CurrentEvent />*/}
            <FlipCardSection />
            <Team />
            <LiveMap />
            <Footer color={null} />
        </>
    );
}

export default Home;