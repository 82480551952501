import React from "react";
import TeamRow from "./TeamRow";

function TeamMembers(props) {
    const bordImg = props.bord;
    return <>
        <TeamRow 
            move={props.row1move} 
            imgs={bordImg.slice(0, props.amount/2)}
        />
        <TeamRow 
            move={props.row2move} 
            imgs={bordImg.slice(props.amount/2, props.amount)}
        />
    </>
}

export default TeamMembers;