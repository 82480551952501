import aida_vlad from "../img/events/Balul Bobocilor/participanti/aida_vlad.jpg";
import adina_denis from "../img/events/Balul Bobocilor/participanti/adina_denis.jpg";
import ana_alex from "../img/events/Balul Bobocilor/participanti/ana_alex.jpg";
import geno_zaco from "../img/events/Balul Bobocilor/participanti/geno_zaco.jpg";
import iustina_lucian from "../img/events/Balul Bobocilor/participanti/iustina_lucian.jpg";
import jesica_andrei from "../img/events/Balul Bobocilor/participanti/jesica_andrei.jpg";
import malina_matei from "../img/events/Balul Bobocilor/participanti/malina_matei.jpg";
import maria_robert from "../img/events/Balul Bobocilor/participanti/maria_robert.jpg";
import miruna_tudor from "../img/events/Balul Bobocilor/participanti/miruna_tudor.jpg";

import aida from "../img/events/Balul Bobocilor/participanti/aida.jpg";
import andrei from "../img/events/Balul Bobocilor/participanti/andrei.jpg";
import jesica from "../img/events/Balul Bobocilor/participanti/jesica.jpg";
import alex from "../img/events/Balul Bobocilor/participanti/alex.jpg";



const balPerechi = [
    {
        imagine: aida_vlad,
        nume1: "Vlad",
        nume2: "Aida"
    },
    {
        imagine: adina_denis,
        nume1: "Denis",
        nume2: "Adina"
    },
    {
        imagine: ana_alex,
        nume1: "Ana",
        nume2: "Alex"
    },
    {
        imagine: geno_zaco,
        nume1: "Andrei",
        nume2: "Genoveva"
    },
    {
        imagine: iustina_lucian,
        nume1: "Iustina",
        nume2: "Lucian"
    },
    {
        imagine: jesica_andrei,
        nume1: "Jesica",
        nume2: "Andrei"
    },
    {
        imagine: malina_matei,
        nume1: "Matei",
        nume2: "Mălina"
    },
    {
        imagine: maria_robert,
        nume1: "Robert",
        nume2: "Maria"
    },
    {
        imagine: miruna_tudor,
        nume1: "Tudor",
        nume2: "Miruna"
    },
]

const balCastigatori = [
    {
        imagine: malina_matei,
        nume: "Mălina & Matei",
        premiu: "Miss & Mister Boboc",
        decoration: "#FFD700"
    },
    {
        imagine: aida,
        nume: "Scripcă Aida",
        premiu: "Miss Boboc locul 2",
        decoration: "#C0C0C0"
    },
    {
        imagine: andrei,
        nume: "Vartolomei Andrei",
        premiu: "Mister Boboc locul 2",
        decoration: "#C0C0C0"
    },
    {
        imagine: jesica,
        nume: "Atitiene Jesica",
        premiu: "Miss Boboc locul 3",
        decoration: "#CD7F32"
    },
    {
        imagine: alex,
        nume: "Simiuc Alexandru",
        premiu: "Mister Boboc locul 3",
        decoration: "#CD7F32"
    },
    {
        imagine: miruna_tudor,
        nume: "Tudor & Miruna",
        premiu: "Miss & Mister Popularitate",
        decoration: "#2B65EC"
    }
]

export { balPerechi, balCastigatori };