import React from "react";
import scoica from "../../img/events/Balul Bobocilor/scoica.png";

function EventPoint(props) {
    return <div className="event-point">
        <img className="event-point-bg" src={scoica} alt="info" />
        <p>{props.text}</p>
    </div>
}

function EventInfo() {
    return <div className="event-info-wrap">
        <div className="event-point-wrap">
            <EventPoint text={"21 NOIEMBRIE ORA 18:00"}/>
            <EventPoint text={"CASA DE CULTURĂ A STUDENŢILOR"}/>
            <EventPoint text={"MASTER CLUB AFTERPARTY"}/>
        </div>
    </div>
}

export default EventInfo;