import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Participants(props) {
    const { imagine, nume1, nume2 } = props.data;
    var random = Math.random()*7 - Math.random()*7

    if (random === 1 )
        random = 6
    else if(random === -1)
        random = -5
    
    return <div className="polaroid-frame" style={{transform: `rotate(${random}deg)`}}>
        <div className="polaroid-photo-bounds">
            <LazyLoadImage src={imagine} alt={`${nume1} ${nume2}`} />
        </div>
        <span className="participants-name">{nume1} & {nume2}</span>
    </div>
}

export default Participants;