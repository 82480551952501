import React from "react";

function FlipCard(props) {
    const { front, back, img } = props.data;

    return <div className="card">
        <div className="card-inner">
            <div className="cfront hide">
                <img src={img} alt={front} />
                {props.overlayOn ? <div className="overlay"></div> : null}
                <div className="text center-parent message nowrap bigger">
                    <p>{front}</p>
                </div>
            </div>
            <div className="cback">
                {back.map((line, index) => {
                    return <React.Fragment key={index}>
                        <p>&emsp;{line}</p>
                    </React.Fragment>
                })}
            </div>
        </div>
    </div>
}

export default FlipCard;