import ac from "../img/sponsors/ac.png";
import allied from "../img/sponsors/allied.png";
import unsr from "../img/sponsors/unsr.png";
import iulius from "../img/sponsors/iulius.png";
import cutia from "../img/sponsors/cutia.png";
import lsac from "../img/sponsors/lsac.jpg";
import css from "../img/sponsors/css.jpg";
import galaxy from "../img/sponsors/galaxy.png";
import pion from "../img/sponsors/pionii.jpeg";
import innovation from "../img/sponsors/innovation.png";
import conti from "../img/sponsors/conti.jpeg";
import levi from "../img/sponsors/levi.png";
import shoreline from "../img/sponsors/shoreline.png";
import sabs from "../img/sponsors/sabs.jpg";
import carre from "../img/sponsors/carrefour.png";
import redbull from "../img/sponsors/redbull.png";
import sass from "../img/sponsors/sas.png";


const sponsors = [
    {
        name: "⭐Allied Testing⭐",
        img: allied,
        link: "https://www.alliedtesting.com/",
        special: true
    },
    {
        name: "Facultatea de Automatică și Calculatoare",
        img: ac,
        link: "https://ac.tuiasi.ro/",
        special: false
    },
    {
        name: "UNSR",
        img: unsr,
        link: "https://unsr.ro/",
        special: false
    },
    {
        name: "Iulius Mall",
        img: iulius,
        link: "http://iuliusmall.com/iasi/",
        special: false
    },
    {
        name: "Cutia Muzicala",
        img: cutia,
        link: "",
        special: false
    },
    {
        name: "LSAC Bucureşti",
        img: lsac,
        link: "https://lsacbucuresti.ro/",
        special: false
    },
    {
        name: "Casa de Cultură a Studenților",
        img: css,
        link: "https://www.ccsiasi.ro/",
        special: false
    },
    {
        name: "Galaxy Arena",
        img: galaxy,
        link: "https://galaxyarena.ro/",
        special: false
    },
    {
        name: "Pionii Regelui",
        img: pion,
        link: "https://pioniiregelui.ro/bineAiVenit",
        special: false
    },
    {
        name: "Innovation Labs",
        img: innovation,
        link: "https://www.innovationlabs.ro/",
        special: false
    },
    {
        name: "Continental",
        img: conti,
        link: "https://www.continental.com/en/",
        special: false
    },
    {
        name: "Levi9",
        img: levi,
        link: "https://www.levi9.com/",
        special: false
    },
    {
        name: "Shoreline",
        img: shoreline,
        link: "https://www.shoreline.io/",
        special: false
    },
    {
        name: "SABS",
        img: sabs,
        link: "https://www.sabs.ro/",
        special: false
    },
    {
        name: "Carrefour",
        img: carre,
        link: "https://carrefour.ro/",
        special: false
    },
    {
        name: "RedBull",
        img: redbull,
        link: "https://www.redbull.com/ro-ro/",
        special: false
    },
    {
        name: "SASS TUIASI",
        img: sass,
        link: "http://sas.tuiasi.ro/",
        special: false
    }
]

export default sponsors;