import React from "react";

function Sponsor(props) {
    const {name, img, link, special} = props.details
    return <div className={`sponsor-bounds center clickable inline-elem ${special ? "special" : ""}`} onClick={()=> window.open(link, "_blank")} >
            <div className="sponsor-image">
                <img className="responsive-img center-parent" src={img} alt={name} />
            </div>
            <div>
                <h3 className="space-text">{name}</h3>
            </div>
        </div>

}

export default Sponsor;