import React, { useState } from "react";
import MemberInfo from "./newsSection/MemberInfo";
import ReactModal from "react-modal";
import SocialMedia from "./sponsorsComponents/SocialMedia";
import { useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import panglica from "../img/events/Christmas/props/panglica.png";

ReactModal.setAppElement("#root");

function Member(props) {
    const { move, link, name, position, profile, description, social, descType, inline } = props.member;
    const descRef = useRef(null);

    var [isModalOpen, setIsModalOpen] = useState(false);
    var [vw, setVw] = useState(window.innerWidth);

    function handleModal() {
        setIsModalOpen(!isModalOpen);
    }

    function setCurrentWidth() {
        setVw(window.innerWidth);
    }

    function showDescription() {
        props.setter(dirDesc);
        setTimeout(scrollTo, 100);
    }

    function scrollTo() {
        descRef.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }

    function closeDesc() {
        props.setter(null);
    }

    setInterval(setCurrentWidth, 1000);

    function modal() {
        return (
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={handleModal}
                className="modal-size modal-style"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <h1>{name}</h1>
                    <h3>{position}</h3>
                    <br />
                    {description.map((element, index) => {
                        return <React.Fragment key={index}>
                            <p className="space">{element}</p><br />
                        </React.Fragment>
                    }
                    )}
                    <div className="absolute middle">
                        <SocialMedia
                            colored={true}
                            icons={social} />
                    </div>
                </div>
                <div className="modal-picture-frame">
                    <img className="responsive-img" src={profile} alt={name} />
                </div>
            </ReactModal>
        );
    }

    function dirDesc() {
        return <div className="director-desc" ref={descRef}>
            <span className="close-button" onClick={closeDesc}><FontAwesomeIcon icon={faXmark} size="2x" /></span>
            {description.map((element, index) => {
                return <React.Fragment key={index}>
                    <p className="space">{element}</p><br />
                </React.Fragment>
            }
            )}
            <div>
                <SocialMedia
                    colored={true}
                    icons={social} />
            </div>
        </div>
    }

    return <div onClick={descType === "modal" ? (vw > 1000 ? handleModal : null) : showDescription} className={`team-img-shape hide ${move ? "team-inner" : ""} ${inline ? "inline-disp" : "block-disp"}`} >
        <MemberInfo
            name={name}
            position={position}
        />
        <div className="christmas-wrap">
            <img className="team-img" src={link} alt="test" />
            {/* <img className="panglica" src={panglica} alt="panglica" /> */}
        </div>
        {descType === "modal" ? modal() : null}
    </div>


}

export default Member;