import React from "react";
import AnimationComponent from "../AnimationComponent";
import Content from "../departmentComponents/Content";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import ChristmasLights from "../ChristmasLights";

function Department(props) {
    const navigate = useNavigate();

    const refreshPage = () => {
        navigate(0);
    }

    return <>
        {refreshPage()}
        <AnimationComponent data={props.details} />
        {/* <ChristmasLights /> */}
        <Content details={props.details.content} />
        <Footer color={props.details.footerColor} />
    </>
}

export default Department;